import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import * as React from "react";
import styled from "styled-components";

import { CommonModal } from "./CommonModal";
import { CommonButton } from "../common/buttons/CommonButton";
import { Heading } from "../common/typography/Heading";

interface UFOModalProps {
  onClose?: () => void;
}

export const UFOModal = (props: UFOModalProps) => {
  const { onClose } = props;

  const [view, setView] = React.useState<"Overview" | "Video" | "Development">(
    "Overview"
  );

  const nextPage = React.useCallback((forwards: boolean) => {
    setView((prev) => {
      switch (prev) {
        case "Overview":
          return forwards ? "Development" : "Development";
        default:
          return forwards ? "Overview" : "Overview";
      }
    });
  }, []);

  const headingButtons = React.useMemo(() => {
    return [
      <CommonButton
        key="UFOPrevButton"
        variant="outlined"
        color="error"
        onClick={() => nextPage(false)}
      >
        <ArrowCircleLeftIcon />
      </CommonButton>,
      <CommonButton
        key="UFONextButton"
        variant="outlined"
        color="error"
        onClick={() => nextPage(true)}
      >
        <ArrowCircleRightIcon />
      </CommonButton>,
    ];
  }, [nextPage]);

  return (
    <StyledModal
      title="UFO"
      subTitle={view}
      headingButtons={headingButtons}
      onClose={onClose}
    >
      {view === "Overview" && (
        <ViewContainer>
          <Description>
            <ViewHeading>Gameplay</ViewHeading>
            Launch into UFO, a disc-golf game set in space! Guide your UFO
            through three dynamic levels containing power-ups and obstacles.
            Landing on a target will earn you points, and the closer to the
            bullseye you hit, the more points you acquire. Do you have what it
            takes to navigate through space?
          </Description>
          <Description>
            <ViewHeading>Controls</ViewHeading>
            {`Use "A" and "W" to aim the UFO. Use "W" and "S" to control the power
            behind the toss. Use the Spacebar to send the UFO flying. To angle
            your throws, use "Q" and "E". Use the "Esc" key to pause the game.`}
          </Description>
        </ViewContainer>
      )}
      {view === "Development" && (
        <ViewContainer>
          <Description>
            <ViewHeading>Development</ViewHeading>
            {`A game created by myself along
            with Marco Agostini, my partner for this project, UFO was created
            during my Computer Game Dev II class over the course of two weeks.
            In the creating of this game, we used Unity version 2020.3.29f1 and
            GitLab. The assignment was to create a unique game using only a very
            small number of provided assets. Marco came up with the idea of
            creating a frisbee type game. We both immediately decided that this
            was going to be our game. I came up with the idea to use Bézier
            curves to control the frisbee. This idea came to me very quickly as
            I had recently used this method to control the path of projectiles
            of a boss for my other group's final project game. The UFO path is
            accomplished using four separate invisible points which the player
            controls. The "W" and "S" keys will move the points closer to the
            player. The "A" and "D" keys will rotate all four points around the
            player. Finally, the "Q" and "E" keys will move only the top two
            points to add a curve to the path of the UFO. In addition to
            creating the player controller, I created the camera system, the
            menus, the point system, and the asteroid obstacles. Impressed with
            our game, our professor recommended that we submit this game to the
            CSCG 2022 Game Jam. We ended up doing just that.`}
          </Description>
        </ViewContainer>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(CommonModal)`
  max-width: 700px;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 400px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 15px;
`;

const ViewHeading = styled(Heading)`
  margin: 0;
`;
