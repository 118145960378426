import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import * as React from "react";
import styled from "styled-components";

import { CommonButton } from "./buttons/CommonButton";

interface SlideshowProps {
  slides: React.ReactNode[];
  defaultSlide?: number;
  className?: string;
}

export const Slideshow = (props: SlideshowProps) => {
  const { slides, defaultSlide, className } = props;

  const [currentSlide, setCurrentSlide] = React.useState<number>(
    defaultSlide && defaultSlide <= slides.length ? defaultSlide : 0
  );

  const nextSlide = React.useCallback(
    (forwards?: boolean) => {
      if (forwards) {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
      } else
        setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    },
    [slides.length]
  );

  return (
    <SlideShowContainer className={className}>
      <CommonButton
        variant="outlined"
        color="primary"
        onClick={() => nextSlide(true)}
      >
        <ArrowCircleLeftIcon />
      </CommonButton>
      <SlideShowContentContainer>
        {slides[currentSlide]}
      </SlideShowContentContainer>
      <CommonButton
        variant="outlined"
        color="primary"
        onClick={() => nextSlide(false)}
      >
        <ArrowCircleRightIcon />
      </CommonButton>
    </SlideShowContainer>
  );
};

const SlideShowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SlideShowContentContainer = styled.div`
  padding: 5px;
`;
