import * as React from "react";
import styled from "styled-components";

interface CloudProps {
  scale?: number;
  bottom?: number;
  left?: number;
  zIndex?: number;
  distance?: number;
  time?: number;
}

export const Cloud = (props: CloudProps) => {
  const { scale, bottom, left, zIndex, distance, time } = props;

  const cloudParts = React.useMemo(() => {
    const parts = [];
    for (let i = scale; i > 0; i--) {
      parts.push({
        left: scale - i,
        scale: (i || 1) / 2,
      });
      parts.push({
        right: scale - i,
        scale: (i || 1) / 2,
      });
    }
    return parts;
  }, []);

  return (
    <StyledCloud
      scale={scale}
      bottom={bottom}
      zIndex={zIndex}
      left={left}
      distance={distance}
      time={time}
    >
      {cloudParts.map((part) => (
        <CloudPart scale={part.scale} left={part.left} right={part.right} />
      ))}
    </StyledCloud>
  );
};

const StyledCloud = styled.div<{
  scale?: number;
  bottom?: number;
  left?: number;
  zIndex?: number;
  distance?: number;
  time?: number;
  popped?: boolean;
}>`
  @keyframes animateCloud {
    0% {
      left: ${(props) => `${props.left ?? 0}px`};
      opacity: 0;
    }
    5% {
      opacity: 75%;
    }
    95% {
      opacity: 75%;
    }
    100% {
      opacity: 0;
      left: ${(props) => `${100 + (props.scale ?? 0 * 5)}%`};
    }
  }

  animation: ${(props) => `animateCloud ${props.time}s linear infinite`};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: ${(props) => props.bottom ?? 25}%;
  position: absolute;
  z-index: ${(props) => props.zIndex ?? "100"};
  left: ${(props) => `${props.left ?? 0}px`};
  left: 350px;
  scale: ${(props) => props.scale ?? 1};
  animation-delay: -${(props) => props.left}s;
`;

const CloudPart = styled.div<{ scale: number; left?: number; right?: number }>`
  position: absolute;
  top: -10px;
  left: ${(props) => (props.left ? `${props.left * 10}px` : "unset")};
  right: ${(props) => (props.right ? `${props.right * 10}px` : "unset")};
  bottom: 0;
  scale: ${(props) => props.scale};
  height: 10px;
  width: 10px;
  background: white;
  border-radius: 50%;
`;
