import * as React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import { AboutMeSection } from "./components/AboutMeSection";
import { BottomSection } from "./components/BottomSection";
import { QualificationsSection } from "./components/QualificationsSection";
import { TitleSection } from "./components/TitleSection";
import { Bubble } from "../../components/common/Bubble";

export const LandingPage = () => {
  const [wormAttached, setWormAttached] = React.useState<boolean>(false);
  const { ref: titleSection, inView } = useInView({
    root: null,
    threshold: 0,
  });

  const [bubbles] = React.useState<React.ReactNode[]>([
    <Bubble key="bubble~1" scale={1} zIndex={10} time={25} left={0} />,
    <Bubble
      key="bubble~2"
      scale={0.2}
      zIndex={10}
      time={34}
      left={window.innerWidth * 0.1}
    />,
    <Bubble
      key="bubble~3"
      scale={0.7}
      zIndex={10}
      time={65}
      left={window.innerWidth * 0.25}
    />,
    <Bubble
      key="bubble~4"
      scale={0.4}
      zIndex={10}
      time={43}
      left={window.innerWidth * 0.5}
    />,
    <Bubble
      key="bubble~5"
      scale={1}
      zIndex={10}
      time={87}
      left={window.innerWidth * 0.6}
    />,
    <Bubble
      key="bubble~6"
      scale={1.2}
      zIndex={10}
      time={29}
      left={window.innerWidth * 0.75}
    />,
  ]);

  React.useEffect(() => {
    if (window.scrollY > 676) {
      setWormAttached(true);
    }
  }, []);

  React.useEffect(() => {
    if (!inView) {
      setWormAttached(true);
    } else {
      setWormAttached(false);
    }
  }, [inView]);

  return (
    <PageContainer>
      <>
        <TitleSection ref={titleSection} />
        <AboutMeSection wormAttached={!!wormAttached} />
        <QualificationsSection />
        <BottomSection />
        {bubbles}
      </>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;
