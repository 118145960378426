import ComputerIcon from "@mui/icons-material/Computer";
import InfoIcon from "@mui/icons-material/Info";
import SchoolIcon from "@mui/icons-material/School";
import * as React from "react";
import styled from "styled-components";

import { useIsDevice } from "../../../../utils/hooks/useDevice";
import { openLink } from "../../../../utils/link-functions";
import { CommonButton } from "../../../components/common/buttons/CommonButton";
import { CommonModule } from "../../../components/common/CommonModule";
import { Section } from "../../../components/common/Section";
import { CopperAshesModal } from "../../../components/modals/CopperAshesModal";
import { EducationModal } from "../../../components/modals/EducationModal";
import { LSTModal } from "../../../components/modals/LSTModal";
import { PGModal } from "../../../components/modals/PGModal";
import { TOSModal } from "../../../components/modals/TOSModal";
import { UFOModal } from "../../../components/modals/UFOModal";

export const QualificationsSection = () => {
  const [modal, setModal] = React.useState<
    "CA" | "LST" | "UFO" | "PG" | "ECU" | "TOS" | "UFO"
  >();
  const [selectedGame, setSelectedGame] = React.useState<"TOS" | "UFO">();
  const isTablet = useIsDevice("tablet");

  return (
    <StyledSection>
      <CommonModule
        heading="Front End Web Developer"
        text="I currently work as a Front End Web Developer for Prometheus Group in Raleigh, NC."
        icon={<ComputerIcon fontSize="large" />}
        footerItems={
          <CommonButton onClick={() => setModal("PG")}>
            More Info
            <InfoIcon />
          </CommonButton>
        }
      />
      <CommonModule
        heading="Education"
        text="I graduated (Magna Cum Laude) with a Bachelor of Science in Computer Science 
        from East Carolina University in May 2022."
        icon={<SchoolIcon fontSize="large" />}
        footerItems={
          <CommonButton onClick={() => setModal("ECU")}>
            More Info
            <InfoIcon />
          </CommonButton>
        }
      />
      <CommonModule
        imageName="copper-ashes-logo"
        heading="Front End Web Developer"
        text="While at ECU, I worked as a Front End Shopify Developer for a small business called Copper Ashes."
        footerItems={
          <>
            <CommonButton onClick={() => setModal("CA")}>
              More Info
              <InfoIcon />
            </CommonButton>
            <CommonButton
              variant="outlined"
              color="info"
              onClick={() => openLink("https://copperashes.org/")}
            >
              Take Me There
            </CommonButton>
          </>
        }
      />
      <CommonModule
        imageName="lets-settle-this-logo"
        heading="Let's Settle This"
        text="Let's Settle This is an Android app which allows for users to play minigames 
        such as Monster Fight and Careful Cards in order to settle disputes."
        footerItems={
          <>
            <CommonButton onClick={() => setModal("LST")}>
              More Info
              <InfoIcon />
            </CommonButton>
            <CommonButton
              variant="outlined"
              color="info"
              onClick={() =>
                openLink(
                  "https://drive.google.com/file/d/1U733lvB4jRwAK80ixRA6TDY0xp39kT-y/view?usp=sharing"
                )
              }
            >
              Download App
            </CommonButton>
          </>
        }
      />
      <CommonModule
        imageName="the-other-side-logo"
        heading="The Other Side"
        text="The Other Side is an endless game where a player tries to set a high-score 
        by helping a chick cross as many roads as possible while dodging dangerous 
        animals."
        footerItems={
          <>
            <CommonButton onClick={() => setModal("TOS")}>
              More Info
              <InfoIcon />
            </CommonButton>
            {!isTablet && (
              <CommonButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSelectedGame("TOS");
                  setTimeout(() => {
                    const container = document.getElementById("TOS-CONTAINER");
                    container.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }, 100);
                }}
              >
                Play Game
              </CommonButton>
            )}
          </>
        }
      />
      {selectedGame === "TOS" && (
        <TOSContainer id="TOS-CONTAINER">
          <TOSCloseButton onClick={() => setSelectedGame(undefined)}>
            X
          </TOSCloseButton>
          <iframe
            title="The Other Side"
            src="https://itch.io/embed-upload/5853923?color=333333"
            width="1042"
            height="788"
          />
          <TOSFooter>
            <CommonButton
              variant="outlined"
              color="info"
              onClick={() =>
                openLink("https://tanandpasty.itch.io/the-other-side")
              }
            >
              Play The Other Side on Itch.io
            </CommonButton>
          </TOSFooter>
        </TOSContainer>
      )}
      <CommonModule
        imageName="ufo-logo"
        heading="UFO"
        text="Unidentified. Frisbee. Object."
        footerItems={
          <>
            <CommonButton onClick={() => setModal("UFO")}>
              More Info
              <InfoIcon />
            </CommonButton>
            {!isTablet && (
              <CommonButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSelectedGame("UFO");
                  setTimeout(() => {
                    const container = document.getElementById("UFO-CONTAINER");
                    container.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }, 100);
                }}
              >
                Play Game
              </CommonButton>
            )}
          </>
        }
      />
      {selectedGame === "UFO" && (
        <UFOContainer id="UFO-CONTAINER">
          <UFOCloseButton onClick={() => setSelectedGame(undefined)}>
            X
          </UFOCloseButton>
          <iframe
            title="UFO"
            src="https://itch.io/embed-upload/5854001?color=333333"
            width="655"
            height="435"
          />
          <UFOFooter>
            <CommonButton
              variant="outlined"
              color="info"
              onClick={() =>
                openLink(
                  "https://tanandpasty.itch.io/ufo-unidentified-frisbee-object"
                )
              }
            >
              Play UFO on Itch.io
            </CommonButton>
          </UFOFooter>
        </UFOContainer>
      )}
      {modal === "PG" && <PGModal onClose={() => setModal(undefined)} />}
      {modal === "CA" && (
        <CopperAshesModal onClose={() => setModal(undefined)} />
      )}
      {modal === "ECU" && (
        <EducationModal onClose={() => setModal(undefined)} />
      )}
      {modal === "LST" && <LSTModal onClose={() => setModal(undefined)} />}
      {modal === "TOS" && <TOSModal onClose={() => setModal(undefined)} />}
      {modal === "UFO" && <UFOModal onClose={() => setModal(undefined)} />}
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  position: relative;
  gap: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: rgb(75, 137, 207);
  background: -moz-linear-gradient(
    180deg,
    rgba(75, 137, 207, 1) 0%,
    rgba(55, 100, 150, 1) 50%,
    rgba(16, 30, 46, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(75, 137, 207, 1) 0%,
    rgba(55, 100, 150, 1) 50%,
    rgba(16, 30, 46, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(75, 137, 207, 1) 0%,
    rgba(55, 100, 150, 1) 50%,
    rgba(16, 30, 46, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4b89cf",endColorstr="#101e2e",GradientType=1);
  padding-bottom: 50px;
`;

const UFOContainer = styled.div`
  position: relative;
  z-index: 100;
  width: 640px;
  height: 380px;
  margin: 25px 0px;
`;

const UFOCloseButton = styled.button`
  position: absolute;
  top: -24px;
  right: -19px;
`;

const UFOFooter = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  bottom: -59px;
  left: 0;
  width: 655px;
  height: 68px;
  background-color: #333333;
  border: 2px solid white;
  border-top: 0;
  border-left: 2px solid #9a9a9a;
`;

const TOSContainer = styled.div`
  position: relative;
  width: 1050px;
  height: 788px;
  z-index: 100;
  margin: 25px 0px;
`;

const TOSCloseButton = styled.button`
  position: absolute;
  top: -24px;
  right: 3px;
`;

const TOSFooter = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  bottom: -24px;
  left: 0;
  width: 1042px;
  height: 68px;
  background-color: #333333;
  border: 2px solid white;
  border-top: 0;
  border-left: 2px solid #9a9a9a;
`;
