import * as React from "react";
import styled from "styled-components";

interface ImageProps {
  fileName: string;
  fileType?: ".jpg" | ".png";
  alt?: string;
  className?: string;
}

export const CommonImage = (props: ImageProps) => {
  const { fileName, fileType, alt, className } = props;

  return (
    <StyledImage
      src={`${process.env.PUBLIC_URL}images/${fileName}${fileType ?? ".png"}`}
      alt={alt ?? fileName}
      className={className}
    />
  );
};

const StyledImage = styled.img`
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  :focus {
    outline: none !important;
  }
`;
