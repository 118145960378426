import CheckIcon from "@mui/icons-material/Check";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import * as React from "react";
import styled from "styled-components";

import { ToastHelper } from "../../../../utils/helpers/ToastHelper";
import { Theme } from "../../../../utils/Theme";
import { CommonButton } from "../../../components/common/buttons/CommonButton";
import { ScrollButton } from "../../../components/common/buttons/ScrollButton";
import { Cloud } from "../../../components/common/Cloud";
import { CommonImage } from "../../../components/common/CommonImage";
import { Section } from "../../../components/common/Section";
import { Heading } from "../../../components/common/typography/Heading";

export const TitleSection = React.forwardRef((props, ref) => {
  const [icon, setIcon] = React.useState<React.ReactNode>(
    <ContentPasteIcon color="action" />
  );
  const timeOut = React.useRef<any>();

  const [clouds] = React.useState<React.ReactNode[]>([
    <Cloud
      key="cloud~1"
      scale={4}
      zIndex={0}
      time={30}
      bottom={20}
      left={10}
    />,
    <Cloud key="cloud~2" scale={3} zIndex={0} time={25} bottom={40} left={0} />,
    <Cloud
      key="cloud~3"
      scale={4}
      zIndex={0}
      time={40}
      bottom={40}
      left={35}
    />,
    <Cloud
      key="cloud~4"
      scale={3}
      zIndex={0}
      time={17}
      bottom={90}
      left={35}
    />,
  ]);

  const ContactMeHandler = React.useCallback(() => {
    navigator.clipboard.writeText("troyalexandernolan@gmail.com").then(() => {
      if (timeOut.current) clearTimeout(timeOut.current);
      setIcon(<CheckIcon color="success" />);
      timeOut.current = setTimeout(
        () => setIcon(<ContentPasteIcon color="action" />),
        4000
      );
      ToastHelper.toast(
        "success",
        "troyalexandernolan@gmail.com has been copied to your clipboard"
      );
    });
  }, []);

  return (
    <>
      <StyledSection ref={ref}>
        <ContentContainer>
          <NameImage fileName="troy-nolan-title" alt="Troy Nolan" />
          <ProfileImage
            fileName="square-profile-picture"
            alt="Troy Nolan Profile Picture"
          />
          <TextContainer>
            <Heading variant="primary" size="medium">
              Front End Web Developer
            </Heading>
            <CommonButton
              color="primary"
              variant="contained"
              onClick={ContactMeHandler}
            >
              <ButtonContents>
                {icon}
                Contact Me
              </ButtonContents>
            </CommonButton>
          </TextContainer>
          <ScrollButton to="ABOUT" duration={200} smooth="easeInOutQuarter" />
        </ContentContainer>
        {clouds}
      </StyledSection>
      <CloudsContainer>
        <CloudsImage fileName="clouds" alt="clouds" />
      </CloudsContainer>
    </>
  );
});

const StyledSection = styled(Section)`
  position: relative;
  background: rgb(84, 167, 245);
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: "#4e6478";
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  max-height: 100%;
`;

const NameImage = styled(CommonImage)`
  max-width: 100%;
  max-height: 40%;
  z-index: 10;
`;

const ProfileImage = styled(CommonImage)`
  max-width: 100%;
  max-height: 45%;
  border-radius: 50%;
  transform: perspective(800px) rotateY(-8deg);
  transition: transform 1s ease 0s;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
  z-index: 10;

  :hover {
    transform: perspective(800px) rotateY(-4deg);
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  margin-bottom: 25px;
`;

const ButtonContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CloudsContainer = styled.div`
  position: relative;
`;

const CloudsImage = styled(CommonImage)`
  position: absolute;
  z-index: 5;
  bottom: -170px;
  width: 100%;

  @media (max-width: ${`${Theme.breakpoints.values.lg}px`}) {
    bottom: -100px;
  }

  @media (max-width: ${`${Theme.breakpoints.values.md}px`}) {
    bottom: -50px;
  }
`;
