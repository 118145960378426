import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import * as React from "react";
import styled from "styled-components";

import { CommonModal } from "./CommonModal";
import { Theme } from "../../../utils/Theme";
import { CommonButton } from "../common/buttons/CommonButton";
import { CommonImage } from "../common/CommonImage";
import { Slideshow } from "../common/Slideshow";
import { Heading } from "../common/typography/Heading";

interface LSTModalProps {
  onClose?: () => void;
}

export const LSTModal = (props: LSTModalProps) => {
  const { onClose } = props;

  const [view, setView] = React.useState<string>("Overview");

  const nextPage = React.useCallback((forwards?: boolean) => {
    setView((prev) => {
      switch (prev) {
        case "Overview":
          return forwards ? "Home Screen" : "Tower Defense";
        case "Home Screen":
          return forwards ? "Careful Cards" : "Overview";
        case "Careful Cards":
          return forwards ? "Monster Fight" : "Home Screen";
        case "Monster Fight":
          return forwards ? "Tower Defense" : "Careful Cards";
        case "Tower Defense":
          return forwards ? "Overview" : "Monster Fight";
        default:
          return "Overview";
      }
    });
  }, []);

  const headingButtons = React.useMemo(
    () => [
      <CommonButton
        key="LSTPrevButton"
        variant="outlined"
        color="error"
        onClick={() => nextPage(false)}
      >
        <ArrowCircleLeftIcon />
      </CommonButton>,
      <CommonButton
        key="LSTNextButton"
        variant="outlined"
        color="error"
        onClick={() => nextPage(true)}
      >
        <ArrowCircleRightIcon />
      </CommonButton>,
    ],
    [nextPage]
  );

  return (
    <StyledModal
      title="Let's Settle This"
      subTitle={view}
      headingButtons={headingButtons}
      onClose={onClose}
    >
      {view === "Overview" && (
        <ViewContainer>
          <StyledLogo fileName="lets-settle-this-logo" />
          <Description>
            <ViewHeading variant="primary" size="medium">
              {`Let's Settle This`}
            </ViewHeading>
            <div>
              During my final semester at ECU, I led a team in the development
              of a minigame-based Android application built using Unity version
              2020.3.29f1. At the time, I had more experience than the rest of
              my team in using Unity and Git. I was able to help them learn
              these necessary skills in order for them to comfortably contribute
              to the project. I created and pitched the idea to the class,
              designed the UI for the main menu, created the assets found in
              each game, and single-handedly designed and implemented Monster
              Fight, Tower Defense, and Careful Cards.
            </div>
          </Description>
        </ViewContainer>
      )}
      {view === "Home Screen" && (
        <ViewContainer>
          <StyledSlideShow
            slides={[
              <StyledImage fileName="lets-settle-this/lets-settle-this-home-screen" />,
              <StyledImage fileName="lets-settle-this/lets-settle-this-players-screen" />,
              <StyledImage fileName="lets-settle-this/lets-settle-this-scoreboard" />,
            ]}
          />
          <Description>
            <ViewHeading variant="primary" size="medium">
              Home Screen
            </ViewHeading>
            <div>
              {`I designed and implemented the navigation of the various menus for
              the application. The "Players" menu allows for the addition and
              removal of players from the game. The "Stats" menu displays the
              different stats of each player. Yes, the poor grammar was
              intentional.`}
            </div>
          </Description>
        </ViewContainer>
      )}
      {view === "Careful Cards" && (
        <ViewContainer>
          <StyledSlideShow
            slides={[
              <StyledImage fileName="lets-settle-this/careful-cards-home-screen" />,
              <StyledImage fileName="lets-settle-this/careful-cards-draw-four" />,
              <StyledImage fileName="lets-settle-this/careful-cards-draw-four-card" />,
              <StyledImage fileName="lets-settle-this/careful-cards-next-player-draw-two-card" />,
              <StyledImage fileName="lets-settle-this/lets-settle-this-switch-turns-card" />,
              <StyledImage fileName="lets-settle-this/careful-cards-you-were-wrong-monster" />,
            ]}
          />
          <Description>
            <ViewHeading variant="primary" size="medium">
              Careful Cards
            </ViewHeading>
            <div>
              {`Careful Cards puts a horror spin on roulette style card games. The
              goal of the game is to be the last player standing. You lose the
              game if you draw a "You Were Wrong" Card. There is a variety of
              cards contained in the deck which make every game unique. Careful
              Cards also contains a "Hot Potato" type aspect as, at any moment
              while it is a player's turn, the "You Were Wrong Monster" may
              pounce on them and remove them from the game.`}
            </div>
          </Description>
        </ViewContainer>
      )}
      {view === "Monster Fight" && (
        <ViewContainer>
          <StyledSlideShow
            slides={[
              <StyledImage fileName="lets-settle-this/monster-fight-home-screen" />,
              <StyledImage fileName="lets-settle-this/monster-fight-instructions-screen" />,
              <StyledImage fileName="lets-settle-this/monster-fight-character-selection-screen" />,
              <StyledImage fileName="lets-settle-this/monster-fight-spaghetti-monster-attack" />,
              <StyledImage fileName="lets-settle-this/monster-fight-movie-monster-attack" />,
            ]}
          />
          <Description>
            <ViewHeading variant="primary" size="medium">
              Monster Fight
            </ViewHeading>
            <div>
              Monster Fight brings the skill-based, intense experiences from
              fighting games such as Mortal Kombat X to your finger-tips. Each
              player has three buttons: Two directional buttons and an attack
              button. In the game, the players are able to select from two
              fighters, perform normal attacks, block oncoming attacks, slowly
              walk or dash, and perform special moves.
            </div>
          </Description>
        </ViewContainer>
      )}
      {view === "Tower Defense" && (
        <ViewContainer>
          <StyledSlideShow
            slides={[
              <StyledImage fileName="lets-settle-this/tower-defense-home-screen" />,
              <StyledImage fileName="lets-settle-this/tower-defense-game-screen" />,
              <StyledImage fileName="lets-settle-this/tower-defense-red-army-attacking" />,
              <StyledImage fileName="lets-settle-this/tower-defense-blue-dragon-attacking" />,
            ]}
          />
          <Description>
            <ViewHeading variant="primary" size="medium">
              Tower Defense
            </ViewHeading>
            <div>
              {`Tower Defense is a game which takes inspiration from simple
              button-spamming games such as Cookie Clicker. The goal of the game
              is to get one of your soldiers to your opponent's tower. By
              tapping on the "Tax Your Subjects" button, you can increase your
              currency. This currency can then be spent on either soldiers or a
              mighty dragon.`}
            </div>
          </Description>
        </ViewContainer>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(CommonModal)`
  max-width: 75vw;
`;

const StyledSlideShow = styled(Slideshow)`
  width: auto;
`;

const StyledImage = styled(CommonImage)`
  width: 100%;
`;

const StyledLogo = styled(CommonImage)`
  width: 250px;
  height: 250px;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;
  width: 100%;
  height: 450px;
  @media (max-width: ${`${Theme.breakpoints.values.md}px`}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 50%;
  @media (max-width: ${`${Theme.breakpoints.values.md}px`}) {
    width: 90%;
  }
`;

const ViewHeading = styled(Heading)`
  margin: 0;
`;
