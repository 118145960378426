import * as React from "react";
import styled from "styled-components";

import { CommonModal } from "./CommonModal";
import { Colors } from "../../../utils/Theme";
import { CommonButton } from "../common/buttons/CommonButton";

interface EducationModalProps {
  onClose?: () => void;
}

export const EducationModal = (props: EducationModalProps) => {
  const { onClose } = props;

  const [sort, setSort] = React.useState<{
    class: "asc" | "desc" | undefined;
    grade: "asc" | "desc" | undefined;
  }>({
    class: undefined,
    grade: undefined,
  });

  const data = [
    {
      class: "Computer Game Development I",
      grade: "A-",
    },
    {
      class: "Computer Game Development II",
      grade: "A",
    },
    {
      class: "Cloud Computing",
      grade: "A",
    },
    {
      class: "Web Applications",
      grade: "A",
    },
    {
      class: "Distributed Computing",
      grade: "B",
    },
    {
      class: "Automata",
      grade: "A",
    },
    {
      class: "Software Engineering I",
      grade: "A",
    },
    {
      class: "Software Engineering II",
      grade: "A",
    },
    {
      class: "Database Management Systems",
      grade: "A-",
    },
    {
      class: "Principles of Programming Languages",
      grade: "A-",
    },
    {
      class: "Design/Analysis of Algorithms",
      grade: "B+",
    },
    {
      class: "Computer Networks",
      grade: "B+",
    },
    {
      class: "Operating Systems",
      grade: "B",
    },
    {
      class: "Algorithms and Data Structures",
      grade: "B+",
    },
    {
      class: "Algorithmic Problem Solving",
      grade: "A",
    },
    {
      class: "Object Oriented Programming",
      grade: "A",
    },
  ];

  const sortedData = React.useMemo(() => {
    if (sort.class) {
      return data.sort((a, b) =>
        sort.class === "asc"
          ? a.class.localeCompare(b.class)
          : b.class.localeCompare(a.class)
      );
    }
    if (sort.grade) {
      return data.sort((a, b) =>
        sort.grade === "asc"
          ? a.grade.localeCompare(b.grade)
          : b.grade.localeCompare(a.grade)
      );
    }
    return data;
  }, [sort]);

  const sortByClassHandler = React.useCallback(() => {
    setSort((prev) => {
      const newSort = { ...prev };
      switch (newSort.class) {
        case "asc":
          newSort.class = undefined;
          break;
        case "desc":
          newSort.class = "asc";
          break;
        default:
          newSort.class = "desc";
          break;
      }
      newSort.grade = undefined;
      return newSort;
    });
  }, []);

  const sortByGradeHandler = React.useCallback(() => {
    setSort((prev) => {
      const newSort = { ...prev };
      switch (newSort.grade) {
        case "asc":
          newSort.grade = undefined;
          break;
        case "desc":
          newSort.grade = "asc";
          break;
        default:
          newSort.grade = "desc";
          break;
      }
      newSort.class = undefined;
      return newSort;
    });
  }, []);

  return (
    <CommonModal
      title="B.S. in Computer Science"
      onClose={onClose}
      innerStyle={{ flexDirection: "column" }}
      subTitle="(Click Headers to Sort)"
    >
      <TableControls>
        <StyledButton color="secondary" onClick={sortByClassHandler}>
          Class
        </StyledButton>
        <StyledButton color="secondary" onClick={sortByGradeHandler}>
          Grade
        </StyledButton>
      </TableControls>
      <StyledTable cellSpacing={0} cellPadding={6}>
        <StyledTableBody>
          {sortedData.map((row) => (
            <StyledTableRow key={row.class}>
              <StyledTableData scope="row">{row.class}</StyledTableData>
              <StyledTableData>{row.grade}</StyledTableData>
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledTable>
    </CommonModal>
  );
};

const TableControls = styled.div`
  position: absolute;
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  background: ${Colors.lightPink};
`;

const StyledButton = styled(CommonButton)`
  font-weight: bolder !important;
`;

const StyledTable = styled.table`
  margin-top: 36px;
  width: 100%;
`;

const StyledTableData = styled.td`
  text-align: left;
  border: none;
`;

const StyledTableRow = styled.tr`
  border: none;
`;

const StyledTableBody = styled.tbody`
  & tr:nth-child(odd) {
    background: #f4f4f4;
  }
  & tr:hover {
    background: #dfdfdf;
  }
`;
