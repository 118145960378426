import { createTheme } from "@mui/material";

export const Colors = {
  pink: "#FF729F",
  lightPurple: "#D3C4D1",
  white: "#FFFFFF",
  lightOrange: "#ffbc00",
  orange: "#faaa56",
  lightBlue: "#56CBF9",
  torquoise: "#81F4E1",
  lightGray: "#98cddf26",
  gray: "#98cddf26",
  green: "#4dc551",
  black: "#000000",
  lightPink: "#ffd5d5",
};

export const Theme = createTheme({
  palette: {
    primary: {
      main: Colors.pink,
    },
    secondary: {
      main: Colors.black,
    },
    error: {
      main: Colors.white,
    },
    warning: {
      main: Colors.orange,
    },
    info: {
      main: Colors.lightBlue,
    },
    success: {
      main: Colors.green,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
