import { toast } from "react-hot-toast";

import { Colors } from "../Theme";

export class ToastHelper {
  static toast = (type: "success" | "error" | "info", message: string) => {
    toast(message, {
      style: {
        background: Colors.green,
        color: Colors.white,
      },
    });
  };
}
