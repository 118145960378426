import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import styled from "styled-components";

import { Colors } from "../../../utils/Theme";
import { CommonButton } from "../common/buttons/CommonButton";

interface BaseModalProps {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  subTitle?: string;
  headingButtons?: React.ReactNode | React.ReactNode[];
  footerButtons?: React.ReactNode | React.ReactNode[];
  className?: string;
  background?: string;
  color?: string;
  innerStyle?: object;
  onClose?: () => void;
}

export const CommonModal = (props: BaseModalProps) => {
  const {
    children,
    title,
    subTitle,
    headingButtons,
    footerButtons,
    className,
    background,
    color,
    innerStyle,
    onClose,
  } = props;

  return (
    <div>
      <Modal open onClose={onClose}>
        <StyledBox className={className}>
          <ModalTop background={background} color={color}>
            <div>
              <Typography id="modal-modal-title" variant="h6">
                {title}
              </Typography>
              {subTitle && <ModalSubTitle>{subTitle}</ModalSubTitle>}
            </div>
            <TopButtons>{headingButtons}</TopButtons>
            <CloseButton onClick={onClose} variant="text" color="error">
              X
            </CloseButton>
          </ModalTop>
          <ModalContent style={innerStyle}>{children}</ModalContent>
          {footerButtons && <ModalFooter>{footerButtons}</ModalFooter>}
        </StyledBox>
      </Modal>
    </div>
  );
};

const StyledBox = styled(Box)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  border-radius: 7px;
  max-width: 500px;
  max-height: 85%;
  overflow: auto;
  margin-right: 30px;
`;

const ModalTop = styled.div<{ background?: string; color?: string }>`
  background: ${(props) => props.background ?? Colors.pink};
  color: ${(props) => props.color ?? Colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 2%;

  h6 {
    font-weight: bold;
  }
`;

const ModalSubTitle = styled.h5`
  margin: 0;
`;

const TopButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    margin: 5px;
  }
`;

const CloseButton = styled(CommonButton)`
  width: 50px;
`;

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  max-height: 500px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ModalUL = styled.ul`
  max-width: 500px;
`;

export const ModalLI = styled.li`
  padding: 3px;
`;
