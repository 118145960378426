import CheckIcon from "@mui/icons-material/Check";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { animated, config, useSpring } from "@react-spring/web";
import * as React from "react";
import styled, { css } from "styled-components";

import { ToastHelper } from "../../../../utils/helpers/ToastHelper";
import { LandingPageManager } from "../../../../utils/managers/LandingPageManager";
import { Bubble } from "../../../components/common/Bubble";
import { CommonButton } from "../../../components/common/buttons/CommonButton";
import { CommonImage } from "../../../components/common/CommonImage";
import { Section } from "../../../components/common/Section";
import { Heading } from "../../../components/common/typography/Heading";

export const BottomSection = () => {
  const [icon, setIcon] = React.useState<React.ReactNode>(
    <ContentPasteIcon color="action" />
  );
  const [bubbleClicked, setBubbleClicked] = React.useState<boolean>(false);
  const [animate, setAnimate] = React.useState<boolean>(false);

  const timeOut = React.useRef<any>();

  const ContactMeHandler = React.useCallback(() => {
    navigator.clipboard.writeText("troyalexandernolan@gmail.com").then(() => {
      if (timeOut.current) clearTimeout(timeOut.current);
      setIcon(<CheckIcon color="success" />);
      timeOut.current = setTimeout(
        () => setIcon(<ContentPasteIcon color="action" />),
        4000
      );
      ToastHelper.toast(
        "info",
        "troyalexandernolan@gmail.com has been copied to your clipboard"
      );
    });
  }, []);

  const [fishSprings, fishSpringsSpi] = useSpring(() => ({
    from: { y: 0, x: 0 },
  }));

  const handleWindowScroll = React.useCallback(() => {
    if (LandingPageManager.wormIsEaten || !LandingPageManager.isFishing) return;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setAnimate(true);
      const newX = window.innerWidth / 2;
      fishSpringsSpi.start({
        from: { y: 0, x: 0 },
        to: [
          { y: 0, x: newX },
          { y: 0, x: 0 },
        ],
        config: { ...config.stiff, duration: 150 },
      });
      LandingPageManager.wormIsEaten = true;
      setTimeout(() => setAnimate(false), 500);
    }
  }, [fishSpringsSpi]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, [handleWindowScroll]);

  return (
    <StyledSection>
      <Heading color="white" variant="primary" size="large">
        {`I'm A Catch...`}
      </Heading>
      <CommonButton
        color="primary"
        variant="contained"
        onClick={ContactMeHandler}
      >
        <ButtonContents>
          {icon}
          Contact Me
        </ButtonContents>
      </CommonButton>
      <BubbleContainer>
        {!bubbleClicked && (
          <Heading color="white" variant="primary" size="medium">
            Pop Me!
          </Heading>
        )}
        <Bubble
          scale={5}
          left={40}
          bottom={-25}
          zIndex={10}
          fixed
          onPop={() => setBubbleClicked(true)}
        />
      </BubbleContainer>
      <DeepFishContainer style={{ ...fishSprings }}>
        <DeepFish animate={animate} fileName="sprite-sheet" />
      </DeepFishContainer>
      <LeftReef fileName="left-reef" />
      <RightReef fileName="right-reef" />
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  position: relative;
  gap: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 400vh;
  background-color: #101e2e;
  overflow: hidden;
`;

const BubbleContainer = styled.div`
  position: relative;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DeepFishContainer = styled(animated.div)`
  position: absolute;
  bottom: -7px;
  width: 40vw;
  overflow: hidden;
  z-index: 5;
`;

const DeepFish = styled(CommonImage)<{ animate?: boolean }>`
  @keyframes animate-deep-fish {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-100%);
    }
  }

  ${(props) =>
    props.animate &&
    css`
      animation: animate-deep-fish 0.1s steps(3) 1;
    `}

  width: 120vw;
`;

const LeftReef = styled(CommonImage)`
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 20%;
  height: 400vh;
`;

const RightReef = styled(CommonImage)`
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 20%;
  height: 400vh;
  z-index: 0;
`;
