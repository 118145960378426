import * as React from "react";
import styled, { css } from "styled-components";

import { ScrollButton } from "./buttons/ScrollButton";

interface SectionProps {
  children?: React.ReactNode | React.ReactNode[];
  background?: string;
  scrollable?: {
    scrollButton?: React.ReactNode | React.ReactNode[];
    color?: string;
    to: string;
    spy?: boolean;
    smooth?: string;
    offset?: number;
    duration?: number;
  };
  className?: string;
  id?: string;
}

export const Section = React.forwardRef((props: SectionProps, ref) => {
  const { children, background, scrollable, className, id } = props;

  return (
    <FullScreenContainer
      ref={ref as React.Ref<HTMLDivElement>}
      background={background}
      className={className}
      id={id}
    >
      {children}
      {scrollable ? (
        <ScrollButton
          to={scrollable.to}
          smooth={scrollable.smooth}
          duration={scrollable.duration}
        />
      ) : null}
    </FullScreenContainer>
  );
});

const FullScreenContainer = styled.section<{ background?: string }>`
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 0;

  ${(props) => css`
    background: ${props.background};
  `}
`;
