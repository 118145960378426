import * as React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";

import { Theme } from "../../../../utils/Theme";

interface ScrollButtonProps {
  to: string;
  smooth?: string;
  duration?: number;
}

export const ScrollButton = (props: ScrollButtonProps) => {
  const { to, smooth, duration } = props;

  return (
    <DefaultScrollIconContainer to={to} duration={duration} smooth={smooth}>
      <DefaultScrollIcon />
    </DefaultScrollIconContainer>
  );
};

const DefaultScrollIconContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5%;
  height: 60px;
  width: 60px;
  border: 1px solid #1b1b1b;
  border-radius: 50%;
  transition: 200ms ease-in-out;
  z-index: 100;
  :hover {
    cursor: pointer;
  }

  @media (min-width: ${`${Theme.breakpoints.values.md}px`}) {
    :hover {
      background: #0e0e0e1f;
      span {
        scale: 1.2;
      }
    }
  }
`;

const DefaultScrollIcon = styled.span`
  bottom: 4px;
  height: 15px;
  width: 15px;
  border: solid #1b1b1b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
`;
