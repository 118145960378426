import * as React from "react";
import styled from "styled-components";

import { CommonImage } from "./CommonImage";
import { Heading } from "./typography/Heading";
import { Theme } from "../../../utils/Theme";

interface ModuleProps {
  imageName?: string;
  heading?: string;
  text?: string;
  footerItems?: React.ReactNode | React.ReactNode[];
  icon?: React.ReactNode;
}

export const CommonModule = (props: ModuleProps) => {
  const { imageName, heading, text, footerItems, icon } = props;

  return (
    <ModuleContainer>
      {imageName && <SectionImage fileName={imageName} />}
      {icon && icon}
      {heading && (
        <Heading size="medium" variant="primary" color="white">
          {heading}
        </Heading>
      )}
      {text && <TextContainer>{text}</TextContainer>}
      <FooterContainer>{footerItems}</FooterContainer>
    </ModuleContainer>
  );
};

const ModuleContainer = styled.div`
  text-align: left;
  position: relative;
  z-index: 10;
  width: 80vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border-radius: 15px;
  background-color: #00000028;
  margin: 25px;

  @media (min-width: ${`${Theme.breakpoints.values.md}px`}) {
    width: 40vw;
  }
`;

const SectionImage = styled(CommonImage)`
  display: block;
  right: 2%;
  top: 0;
  max-width: 75%;
  z-index: 4;
  @media (min-width: ${`${Theme.breakpoints.values.md}px`}) {
    max-width: 300px;
  }
`;

const TextContainer = styled.div`
  padding: 3%;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;
