import * as React from "react";

import { Theme } from "../Theme";

export const useIsDevice = (type?: "phone" | "tablet") => {
  const [isPhone] = React.useState<boolean>(
    window.innerWidth <= Theme.breakpoints.values.sm
  );
  const [isTablet] = React.useState<boolean>(
    window.innerWidth <= Theme.breakpoints.values.md
  );

  return type === "phone" ? isPhone : isTablet;
};
