import { Button as MatUIButton } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

import { Colors } from "../../../../utils/Theme";

interface ButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  variant?: "contained" | "outlined" | "text";
  className?: string;
  disableRipple?: boolean;
  disableFocusRipple?: boolean;
  disabled?: boolean;
  callToAction?: string;
  onClick: () => void;
}

export const CommonButton = (props: ButtonProps) => {
  const {
    children,
    color,
    variant,
    className,
    disableRipple,
    disableFocusRipple,
    disabled,
    callToAction,
    onClick,
  } = props;

  return (
    <ButtonContainer>
      <MatUIButton
        className={className}
        variant={variant}
        color={color}
        onClick={() => onClick()}
        disableRipple={disableRipple}
        disableFocusRipple={disableFocusRipple}
        disabled={disabled}
      >
        {children}
        {callToAction && <CallToAction>{callToAction}</CallToAction>}
      </MatUIButton>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  position: relative;
`;

const CallToAction = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  color: ${Colors.white};
  background: ${Colors.lightOrange};
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
`;
