import * as React from "react";

import { CommonModal, ModalLI, ModalUL } from "./CommonModal";
import { Colors } from "../../../utils/Theme";

interface CopperAshesModalProps {
  onClose?: () => void;
}

export const CopperAshesModal = (props: CopperAshesModalProps) => {
  const { onClose } = props;

  return (
    <CommonModal
      title="Copper Ashes"
      onClose={onClose}
      background={Colors.pink}
    >
      <ModalUL>
        <ModalLI>
          {`Single-handedly learned Shopify Liquid, Shopify's templating language,
          as well as the codebase for Shopify's Dawn theme.`}
        </ModalLI>
        <ModalLI>
          Developed an automation script using Python and Selenium which sent
          Instagram messages to thousands of followers.
        </ModalLI>
        <ModalLI>
          Improved the styling of the website through adding and modifying
          dynamic CSS classes across multiple sections.
        </ModalLI>
        <ModalLI>
          Provided the maintainer of the Copper Ashes website with custom
          settings for drag-and-drop elements that she can easily use without
          any coding experience.
        </ModalLI>
      </ModalUL>
    </CommonModal>
  );
};
