import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import * as React from "react";
import styled from "styled-components";

import { CommonModal } from "./CommonModal";
import { CommonButton } from "../common/buttons/CommonButton";
import { Heading } from "../common/typography/Heading";

interface TOSModalProps {
  onClose?: () => void;
}

export const TOSModal = (props: TOSModalProps) => {
  const { onClose } = props;

  const [view, setView] = React.useState<"Overview" | "Video" | "Development">(
    "Overview"
  );

  const nextPage = React.useCallback((forwards: boolean) => {
    setView((prev) => {
      switch (prev) {
        case "Overview":
          return forwards ? "Development" : "Video";
        case "Development":
          return forwards ? "Video" : "Overview";
        default:
          return forwards ? "Overview" : "Development";
      }
    });
  }, []);

  const headingButtons = React.useMemo(() => {
    return [
      <CommonButton
        key="TOSPrevButton"
        variant="outlined"
        color="error"
        onClick={() => nextPage(false)}
      >
        <ArrowCircleLeftIcon />
      </CommonButton>,
      <CommonButton
        key="TOSNextButton"
        variant="outlined"
        color="error"
        onClick={() => nextPage(true)}
      >
        <ArrowCircleRightIcon />
      </CommonButton>,
    ];
  }, [nextPage]);

  return (
    <StyledModal
      title="The Other Side"
      subTitle={view}
      headingButtons={headingButtons}
      onClose={onClose}
    >
      {view === "Overview" && (
        <ViewContainer>
          <Description>
            <ViewHeading>Gameplay</ViewHeading>
            You play as a tiny chick with a caffeine addiction. Guide this chick
            through an endless number of roads while avoiding stampeding
            animals. Move quickly and keep your stamina filled to the brim by
            picking up various food items. Without any stamina, the chick is
            easily outran by the monstrous bulldog hiding out in the woods. How
            far will you get before your chick becomes too tired to carry on?
          </Description>
          <Description>
            <ViewHeading>Controls</ViewHeading>
            {`Use the "W", "A", "S", "D" keys to move around. Use the "Esc" key to
            pause the game.`}
          </Description>
        </ViewContainer>
      )}
      {view === "Video" && (
        <ViewContainer>
          <ViewHeading>Game Jam Submission Video</ViewHeading>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1VoiFeA5L94"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </ViewContainer>
      )}
      {view === "Development" && (
        <ViewContainer>
          <Description>
            <ViewHeading>Development</ViewHeading>
            This game was based off of the game which I helped create alongside
            my Computer Game Development II group during my spring 2022 semester
            at East Carolina University. Once I began working on UFO with a
            different group, my professor approached us and said that we should
            submit UFO to the CSGC 2022 conference which was held in three days.
            I agreed that it would be a good idea, but I determined that UFO was
            not the game which I wanted to submit. I worked hard on the level
            generation for The Other Side, and I wanted to build upon that hard
            work to make something I was completely satisfied with. Over 40+
            hours that weekend, I worked tirelessly on making The Other Side as
            polished as I could. I added the new menus, I reworked the character
            controls, I completely reworked the animals (the spawn system and
            speed of each animal), I updated the item spawn system, I added new
            unlockable/playable characters, I added the bulldog boss which
            spawns every ten levels, I added music and sound effects- I was
            finally satisfied and proud of my final product. The day following
            my submission, my professor reached out to me and told me that he
            found an issue with my game. The anchor point for the level number
            was not set properly which resulted in the number being outside of
            its designated area. Being that it was too late to resubmit the
            game, I was pretty bummed out. While I did not place in the top
            three for the Game Jam, I was still very proud of my finished game
            and learned plently to make the time spent creating it worth my
            while.
          </Description>
        </ViewContainer>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(CommonModal)`
  max-width: 700px;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 400px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 15px;
`;

const ViewHeading = styled(Heading)`
  margin: 0;
`;
