import * as React from "react";
import styled, { css } from "styled-components";

interface HeadingProps {
  children: React.ReactNode | React.ReactNode[] | string;
  variant?: "primary" | "secondary";
  size?: "large" | "medium" | "small";
  align?: "left" | "center" | "right";
  color?: string;
  className?: string;
  style?: object;
}

// eslint-disable-next-line react/function-component-definition
export const Heading = (props: HeadingProps) => {
  const { children, variant, size, style, align, color, className } = props;

  return variant === "primary" ? (
    <StyledPrimaryHeading
      color={color}
      size={size}
      align={align}
      className={className}
      style={style}
    >
      {children}
    </StyledPrimaryHeading>
  ) : (
    <StyledSecondaryHeading
      color={color}
      size={size}
      align={align}
      className={className}
      style={style}
    >
      {children}
    </StyledSecondaryHeading>
  );
};

const StyledPrimaryHeading = styled.h1<{
  size?: "large" | "medium" | "small";
  align?: "left" | "center" | "right";
  color?: string;
}>`
  ${(props) => css`
    font-size: ${props.size === "large"
      ? "2rem"
      : props.size === "medium"
      ? "1.4rem"
      : "1rem"};
    text-align: ${props.align ?? "left"};
    color: ${props.color ?? "black"};
  `}
`;

const StyledSecondaryHeading = styled.h2<{
  size?: "large" | "medium" | "small";
  align?: "left" | "center" | "right";
  color?: string;
}>`
  ${(props) => css`
    font-size: ${props.size === "large"
      ? "1rem"
      : props.size === "medium"
      ? ".5rem"
      : "1rem"};
    text-align: ${props.align ?? "left"};
    color: ${props.color ?? "black"};
  `};
`;
