import { animated, config, useSpring } from "@react-spring/web";
import * as React from "react";
import styled from "styled-components";

import { Colors } from "../../../utils/Theme";

interface BubbleProps {
  scale?: number;
  bottom?: number;
  left?: number;
  zIndex?: number;
  distance?: number;
  time?: number;
  fixed?: boolean;
  onPop?: () => void;
}

export const Bubble = (props: BubbleProps) => {
  const { scale, bottom, left, fixed, zIndex, distance, time, onPop } = props;

  const [popped, setPopped] = React.useState<boolean>(false);
  const [popCoordinates, setPopCoordinates] = React.useState<[number, number]>([
    0, 0,
  ]);

  const [popSprings, popSpringsApi] = useSpring(() => ({
    from: { scale: 0 },
  }));

  const displayPopText = (e: any) => {
    setPopped(true);
    popSpringsApi.start({
      from: { scale: 0 },
      to: [{ scale: 3 }, { scale: 0 }],
      config: { ...config.wobbly },
    });
    if (!fixed) {
      setTimeout(() => setPopped(false), 1000);
    }
    setPopCoordinates([e.clientX, e.pageY]);
    onPop?.();
  };

  return popped ? (
    <PopText
      left={fixed ? left : popCoordinates[0]}
      top={fixed ? bottom : popCoordinates[1]}
      style={{ ...popSprings }}
    >
      POP
    </PopText>
  ) : (
    <StyledBubble
      scale={scale}
      bottom={bottom}
      left={left}
      zIndex={zIndex}
      distance={distance}
      time={time}
      popped={popped}
      fixed={fixed}
      onClick={displayPopText}
    >
      <BubbleDecorationSmall />
      <BubbleDecorationLarge />
    </StyledBubble>
  );
};

const PopText = styled(animated.h1)<{ left?: number; top?: number }>`
  position: absolute;
  top: ${(props) => `${props.top ?? 0}px`};
  left: ${(props) => `${props.left ?? 0}px`};
  color: ${Colors.white};
  z-index: 1000;
  font-weight: bolder;
`;

const BubbleDecorationSmall = styled.div`
  width: 10%;
  height: 10%;
  background: ${Colors.lightGray};
  position: absolute;
  top: 5%;
  border-radius: 50%;
  left: 50%;
`;

const BubbleDecorationLarge = styled.div`
  width: 20%;
  height: 20%;
  background: ${Colors.lightGray};
  position: absolute;
  top: 15%;
  border-radius: 50%;
  left: 35%;
`;

const StyledBubble = styled.div<{
  scale?: number;
  bottom?: number;
  left?: number;
  zIndex?: number;
  distance?: number;
  time?: number;
  popped?: boolean;
  fixed?: boolean;
}>`
  @keyframes animateBubble {
    0% {
      opacity: 0;
      height: 10vw;
      width: 10vw;
      bottom: 0;
    }
    5% {
      opacity: 1;
    }
    98% {
      height: 10vw;
      width: 10vw;
      opacity: 1;
    }
    100% {
      opacity: 0;
      height: 0;
      width: 0;
      bottom: 700vh;
    }
  }

  @keyframes sideWays {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(100px);
    }
  }

  @keyframes popBubble {
    0% {
      height: 200px;
      width: 200px;
    }
    90% {
      height: 0;
      width: 0;
    }
    100% {
      bottom: -400px;
    }
  }
  animation: ${(props) => {
    const animation: string[] = [];
    if (!props.fixed && !props.popped) {
      animation.push(`animateBubble ${props.time ?? 25}s linear infinite`);
    }
    if (!props.fixed) {
      animation.push(`sideWays 2s ease-in-out infinite alternate`);
    }
    if (props.popped) {
      animation.push("popBubble 1s steps");
    }
    return animation.join(", ");
  }};

  background-color: #c7c7ff31;
  position: absolute;
  height: 10px;
  width: 10px;
  z-index: ${(props) => props.zIndex ?? "100"};
  border-radius: 50%;
  left: ${(props) => `${props.left ?? 0}px`};
  bottom: ${(props) => `${props.bottom ?? 0}px`};
  scale: ${(props) => props.scale ?? 1};
  :hover {
    cursor: pointer;
  }
`;
