import * as React from "react";

import { CommonModal, ModalLI, ModalUL } from "./CommonModal";

interface PGModalProps {
  onClose?: () => void;
}

export const PGModal = (props: PGModalProps) => {
  const { onClose } = props;

  return (
    <CommonModal title="Prometheus Group" onClose={onClose}>
      <ModalUL>
        <ModalLI>
          Collaborated with a team to build solutions surrounding permitting and
          safety using React and the .NET Entity framework.
        </ModalLI>
        <ModalLI>
          Implemented and demoed the front-end design and functionality for a
          feature which allows for users to upload, view, transform, and markup
          relevant documents.
        </ModalLI>
        <ModalLI>
          Singlehandedly implemented the front end for over five, large,
          customer facing features.
        </ModalLI>
        <ModalLI>
          Used React Final Form, Styled Components, React Signature Canvas,
          React Select, and React Date Picker to implement a new fields system.
        </ModalLI>
        <ModalLI>Redesigned and overhauled our app settings screen.</ModalLI>
        <ModalLI>Fixed hundreds of bugs.</ModalLI>
        <ModalLI>
          Created a React application to work as the front end for an internal
          continuous integration tool.
        </ModalLI>
      </ModalUL>
    </CommonModal>
  );
};
