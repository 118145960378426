import PhishingIcon from "@mui/icons-material/Phishing";
import { animated, config, useSpring } from "@react-spring/web";
import * as React from "react";
import styled from "styled-components";

import { openLink } from "../../../../utils/link-functions";
import { LandingPageManager } from "../../../../utils/managers/LandingPageManager";
import { Colors, Theme } from "../../../../utils/Theme";
import { CommonButton } from "../../../components/common/buttons/CommonButton";
import { CommonImage } from "../../../components/common/CommonImage";
import { Section } from "../../../components/common/Section";
import { Heading } from "../../../components/common/typography/Heading";

interface AboutMeSectionProps {
  wormAttached: boolean;
}

export const AboutMeSection = (props: AboutMeSectionProps) => {
  const { wormAttached } = props;

  const [isFishing, setIsFishing] = React.useState<boolean>(false);
  const [canToggleFishing, setCanToggleFishing] = React.useState<boolean>(true);
  const [wormIsEaten, setWormIsEaten] = React.useState<boolean>(false);

  const handleWindowScroll = React.useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setWormIsEaten(true);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, [handleWindowScroll]);

  const [wormSprings, wormSpringsApi] = useSpring(() => ({
    from: { y: -1000, x: 0 },
  }));

  const handleWormClick = React.useCallback(() => {
    const wormNewPosition = wormIsEaten ? -1000 : -100;
    wormSpringsApi.start({
      from: { y: 0, x: 0 },
      to: [
        { y: wormNewPosition, x: 0 },
        { y: 0, x: 0 },
      ],
      config: { ...config.stiff },
    });
    setTimeout(() => {
      LandingPageManager.wormIsEaten = false;
      setWormIsEaten(false);
    }, 500);
  }, [wormIsEaten, wormSpringsApi]);

  const handleToggleFishing = React.useCallback(() => {
    setCanToggleFishing(false);
    if (!isFishing) {
      setWormIsEaten(false);
      LandingPageManager.wormIsEaten = false;
      wormSpringsApi.start({
        from: { y: -1000, x: 0 },
        to: [{ y: 0, x: 0 }],
        config: { ...config.stiff },
      });
      setTimeout(() => {
        setIsFishing(true);
        LandingPageManager.isFishing = true;
        setCanToggleFishing(true);
      }, 500);
    } else {
      wormSpringsApi.start({
        from: { y: 0, x: 0 },
        to: [{ y: -1000, x: 0 }],
        config: { ...config.stiff },
      });
      setTimeout(() => {
        setIsFishing(false);
        LandingPageManager.isFishing = false;
        setCanToggleFishing(true);
      }, 500);
    }
  }, [isFishing, wormSpringsApi]);

  const [frontWaveSprings] = useSpring(() => ({
    from: { y: 0, x: 0 },
    to: [
      { y: -10, x: 0 },
      { y: 0, x: 0 },
    ],
    loop: true,
    config: { ...config.gentle, duration: 2000 },
  }));

  const [midWaveSprings] = useSpring(() => ({
    from: { y: 0, x: 0 },
    to: [
      { y: -10, x: 0 },
      { y: 0, x: 0 },
    ],
    loop: true,
    config: { ...config.gentle, duration: 3000 },
  }));

  return (
    <StyledSection id="ABOUT">
      <TextContainer>
        <Heading variant="primary" size="medium">
          Hello,
        </Heading>
        <Heading variant="secondary" size="large">
          My name is Troy Nolan. I am a front end web developer who specializes
          in building intuitive, exciting user experiences using React.
        </Heading>
        <BottomText>
          <SubText>There is plenty more under the surface.</SubText>
        </BottomText>
        <ActionButtons>
          <ActionButton
            variant="outlined"
            color="info"
            onClick={() =>
              openLink(`${process.env.PUBLIC_URL}/Troy-Nolan-resume.pdf`)
            }
          >
            Resume
          </ActionButton>
          <ActionButton
            disabled={!canToggleFishing}
            callToAction="!"
            variant="outlined"
            color="primary"
            onClick={() => handleToggleFishing()}
          >
            <PhishingIcon />
          </ActionButton>
        </ActionButtons>
      </TextContainer>
      <WormContainer
        onClick={handleWormClick}
        style={{ ...wormSprings }}
        attached={wormAttached}
      >
        {!wormIsEaten ? (
          <WormImage fileName="worm" />
        ) : (
          <BrokenLineImage fileName="broken-line" />
        )}
      </WormContainer>
      <WavesBackground />
      <BottomContainer>
        <HorizonCloudsImage fileName="horizon-clouds" />
        <WavesContainer>
          <FrontWaterContainer style={{ ...frontWaveSprings }}>
            <IslandImage fileName="island" />
            <FrontWaterImage fileName="front-water" />
          </FrontWaterContainer>
          <MidWaterContainer style={{ ...midWaveSprings }}>
            <MidWaterImage fileName="mid-water" />
          </MidWaterContainer>
        </WavesContainer>
      </BottomContainer>
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  background: linear-gradient(
    180deg,
    rgba(84, 167, 245, 1) 0%,
    rgba(84, 167, 245, 1) 63%,
    rgba(255, 202, 121, 1) 100%
  );
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const TextContainer = styled.div`
  position: relative;
  align-self: center;
  margin: 0px 10%;
  justify-self: center;
  z-index: 100;
  border-radius: 10px;
  max-width: 500px;
  background-color: ${Colors.white};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 6px solid ${Colors.pink};
  padding: 2%;
  &::after {
    content: " ";
    position: relative;
    width: 10px;
    background: ${Colors.pink};
  }
`;

const BottomText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ActionButton = styled(CommonButton)`
  margin: 5px !important;
`;

const SubText = styled.p`
  font-style: italic;
  font-size: 1rem;
  margin: 3px;
`;

const WormContainer = styled(animated.div)<{ attached?: boolean }>`
  position: ${(props) => (props.attached ? "fixed" : "absolute")};
  right: 2%;
  top: 0;
  z-index: 4;
  :hover {
    cursor: pointer;
  }
`;

const WormImage = styled(CommonImage)`
  height: 85vh;
`;

const BrokenLineImage = styled(CommonImage)`
  height: 85vh;
`;

const HorizonCloudsImage = styled(CommonImage)`
  position: absolute;
  left: 0;
  bottom: 5%;
  z-index: -1;
  width: 100%;
`;

const WavesBackground = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5%;
  background: #4b89cf;
  @media (min-width: ${`${Theme.breakpoints.values.md}px`}) {
    height: 10%;
  }
`;

const BottomContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 20vh;
  bottom: 0px;
  align-self: flex-end;
`;

const WavesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
`;

const FrontWaterContainer = styled(animated.div)`
  position: absolute;
  bottom: 0px;
  z-index: 10;
  width: 100%;
`;

const FrontWaterImage = styled(CommonImage)`
  width: 100%;
`;

const MidWaterContainer = styled(animated.div)`
  position: absolute;
  bottom: 0px;
  z-index: 2;
  width: 100%;
`;

const MidWaterImage = styled(CommonImage)`
  width: 100%;
`;

const IslandImage = styled(CommonImage)`
  position: absolute;
  left: 0;
  z-index: -1;
  bottom: 35%;
  width: 30%;
  background-color: none;
`;
